import MsgWindow from './MsgWindow';
import IconPhone from '@/assets/sticker/phone.svg';
import styles from './index.module.scss';
import { useState }  from 'react'

const Sticker = () => {
    const [show, setShow] = useState(false)
    return (
        <div className={styles.sticker}>
            <div className={styles.phone} onMouseLeave={() => setShow(false)} >
                <div className={styles.msg} style={{display: show ? 'block' : 'none'}} >
                    <MsgWindow />
                </div>
                <div
                    className={styles.icon}
                    onMouseEnter={() => setShow(true)}
                >
                    <img src={IconPhone} alt={'phone'} style={{ height: 30, width: 30 }} />
                </div>
            </div>
        </div>
    )
}

export default Sticker