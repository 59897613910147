import React, {
    ComponentType,
    lazy,
    LazyExoticComponent,
    ReactNode,
} from 'react';

export interface IRoute {
    path: string;
    exact: boolean;
    fallback?: NonNullable<ReactNode> | null;
    component?: LazyExoticComponent<ComponentType<any>>;
    routes?: IRoute[];
    redirect?: string;
    private?: boolean;
}

export const routes: IRoute[] = [
    {
        path: '/',
        exact: true,
        redirect: '/home',
    },
    {
        path: '/home',
        exact: false,
        private: false,
        component: lazy(() => import('@/pages/Home')),
    },
    {
        path: '/mnStack',
        exact: false,
        private: false,
        component: lazy(() => import('@/pages/MtStack')),
    },
    {
        path: '/data-storage',
        exact: false,
        private: false,
        component: lazy(() => import('@/pages/DataStorage')),
    },
    {
        path: '/about',
        exact: false,
        private: false,
        component: lazy(() => import('@/pages/About')),
    },
    {
        path: '/big-data',
        exact: false,
        private: false,
        component: lazy(() => import('@/pages/BigData')),
    },
    {
        path: '/cloud-security',
        exact: false,
        private: false,
        component: lazy(() => import('@/pages/CloudSecurity')),
    },
    {
        path: '/cloud-service',
        exact: false,
        private: false,
        component: lazy(() => import('@/pages/CloudService')),
    },
    {
        path: '/solution',
        exact: false,
        private: false,
        component: lazy(() => import('@/pages/Solution')),
    },
    {
        path: '/contact-us',
        exact: false,
        private: false,
        component: lazy(() => import('@/pages/ContactUs')),
    },
    {
        path: '/box',
        exact: false,
        private: false,
        component: lazy(() => import('@/pages/Box')),
    },
    {
        path: '/recruitment',
        exact: false,
        private: false,
        component: lazy(() => import('@/pages/Recruitment')),
    },
];