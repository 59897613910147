import React from 'react';

import Logo from '@/assets/nav/logo1.svg';
import Location from '@/assets/footer/location.svg';
import IconPhone from '@/assets/footer/icon-phone.svg';
import IconFile from '@/assets/footer/icon-file.svg';

import styles from './index.module.scss'

const Info = () => {
    return (
        <div className={styles.container}>
            
            <section style={{ marginTop: 20, marginBottom: 24 }}>
                <img src={Logo} alt={'logo'} style={{ width: '70px', height: '45px' }}/>

                <header className={styles.header}>
                <p>四川多米电子商务有限公司</p>
                </header>
            </section>

        
            
            {/* <section className={styles.location}>
                <img src={Location} alt={'location'} style={{ paddingRight: 6}} />
                <p className={styles.text} style={{ letterSpacing:0.1 }}>浙江省杭州市余杭区仓前街道欧美金融城T4幢905室3号</p>
            </section> */}
            
            <section>
                 <div className={styles.contact}>
                    <div className={styles.icon}>
                        <img src={IconPhone} alt={'icon-phone'} />
                    </div>
                    <div className={styles.texts}>

                        <p className={`${styles.text} ${styles.textSize}`}>    
                            <span className={styles.label}>业务 周先生：</span>
                            <span className={styles.value}>18628021562</span>
                        </p>
                        
                        <p className={`${styles.text} ${styles.textSize}`}>  
                            <span className={styles.label}>技术 李先生：</span>
                            <span className={styles.value}>18883196207</span>
                        </p>
                    </div>
                </div> 
                
                <div className={styles.contact}>
                    <div className={styles.icon}>
                        <img src={IconFile} alt={'icon-file'} />
                    </div>
                    <div className={styles.texts}>
                        <p className={`${styles.text} ${styles.textSize}`}>
                            <span className={styles.label}>业务邮件：</span>
                            <a className={`${styles.value} ${styles.email}`} href={"mailto:1756879470@qq.com"}>1756879470@qq.com</a>
                            {/* <span className={styles.phone}>mtyw@mty.wang</span> */}
                        </p>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Info;