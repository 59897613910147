import React from 'react';
import { Spin } from 'antd';

import styles from './index.module.scss';

const Loading = () => {
    return (
        <div className={styles.container}>
            <div className={styles.nav} />   
            <div className={styles.spinWrapper} >
                <Spin size={"large"} />
            </div> 
        </div>
    )
}

export default Loading;