import React from 'react';

import IconPolice from '@/assets/footer/police.png';

import styles from './index.module.scss'

const Police = () => {
    return (
        <div className={styles.container}>
            <div className={styles.copyright}>
                <p>© 2011-2024 domipayment.com 版权所有</p>
            </div>

            <div className={styles.police}>
                <img src={IconPolice} style={{ width: 43, height: 18 }} alt={'police'} />
                <p className={styles.text}>
                    <span className={styles.ipcLink} onClick={() => { window.open(`https://beian.miit.gov.cn/`) }}>
                    蜀ICP备16009353号-2
                    </span>
                    {/* <span className={styles.ipcLink} onClick={() => { window.open(`http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33011002016128`) }}>
                        川公网安备 54211002016128号
                    </span> */}
                </p>
            </div>
        </div>
    )
}

export default Police