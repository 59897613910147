import React, { useState } from 'react';
import app from '@/assets/sticker/app.png';
import { QRCodeCanvas } from 'qrcode.react';
import styles from './index.module.scss';


const DownloadApp = () => {
    
    
    const [msgVisible, setMsgVisible] = useState<boolean>(false);
    return (
        <div className={styles.sticker}>
            <div className={styles.phone} onMouseLeave={() => setMsgVisible(false)}>
                <div
                    className={styles.wrapper}
                    style={{ visibility: msgVisible ? 'visible' : 'hidden' }}
                >
                    <p style={{ color: '#333', fontWeight: 'bold' }}>Kepule APP</p>
                    <QRCodeCanvas size={95} value={' https://oss.mty.wang/kpl/kepule.apk'} />
                    <p style={{ color: '#999', fontSize: 14 }}>扫一扫，立即下载APP</p>
                </div>
                <div
                    className={styles.icon}
                    onMouseOver={() => setMsgVisible(false)}
                >
                    <img src={app} alt={'app'} style={{ height: 30, width: 30 }} />
                </div>
            </div>
        </div>
    )    
}

export default DownloadApp