import React from 'react';

import { menus } from './data'

import styles from './index.module.scss'

const Menus = () => {
    return (
        <div className={styles.container}>
            {menus.map(menu => {
                
                return (<div key={menu.id} className={styles.menuItem}>
                    <div className={styles.title}>{menu.title}</div>
                    <ul>
                        {menu.list.map(nav => {
                            return (<li 
                                key={nav.key} 
                                className={styles.nav} 
                                onClick={() => {
                                    if(nav?.to) {
                                        window.open(`${nav.to}`, '_blank');
                                    }
                                }}
                                
                            >
                                <p>{nav.name}</p>
                            </li>)
                        })}
                    </ul>
                </div>);
            })}
        </div>
    )
}

export default Menus