import React from 'react';

import SectHeader from './Header';
import SectMenus from './Menus';
import SectInfo from './Info';
import SectPolice from "./Police";
import BgImage from '@/assets/footer/bg.png';

import styles from './index.module.scss'

const Footer = () => {
    return (<>
        <div
            className={styles.container}
            style={{ backgroundImage: `url(${BgImage})` }}
        >
            <section className={styles.header}>
                <SectHeader />
            </section>

            <section className={styles.main}>
                <div className={styles.menus}>
                    <SectMenus />
                </div>
                <div className={styles.info}>
                    <SectInfo />
                </div>
            </section>
        </div>

        <div>
            <SectPolice />
        </div>
    </>)
}

export default Footer