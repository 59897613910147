import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import HeaderTop from './HeaderTop';
import Navigator from './Navigator'

import styles from "./index.module.scss";

const THRESHHOLD = 600 - 122;

const Header = () => {
    const [y, setY] = useState(window.scrollY);

    const handleNavigation = (e: any) => {
        setY(e.currentTarget.scrollY);
    };

    useEffect(() => {
        window.addEventListener("scroll", (e) => handleNavigation(e));

        return () => {
            window.removeEventListener("scroll", (e) => handleNavigation(e));
        };
    }, [y]);

    return (
        <header className={styles.container}>
            <section
                className={classnames(styles.headerWrapper, y > 0 && styles.active)}
            >
                <HeaderTop />
                <Navigator />
            </section>
        </header>
    );
}

export default Header;