import React from 'react';
import { NavLink } from 'react-router-dom';

import { Menus } from '@/router/menus'

import styles from "./index.module.scss";

const Navigator = () => {
    return (
        <nav className={styles.headerNav}>
            <ul className={styles.navWrapper}>
                {Menus.map(menu => {
                    const { title, className } = menu;
                    return (
                        <NavLink
                            className={className || 'text-lg'}
                            activeStyle={{
                                fontWeight: "bold",
                                backgroundColor: "rgba(255, 255, 255, 0.1)",
                                // color: '#333',
                            }}
                            {...menu}
                        >
                            {title}
                        </NavLink>
                    );
                })}
            </ul>
        </nav>
    );
}

export default Navigator;
