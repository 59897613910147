import type { NavLinkProps } from 'react-router-dom';

export type LinkParams = NavLinkProps & {
    key: string;
    title: string;
    style?: AnyObject;
    className?: string;
}

export const Menus: LinkParams[] = [
    {
        key: 'home',
        title: '首页',
        to: '/home',
    },
   //
   // {
   //     key: 'mnStack',
   //     title: '私有云存储',
   //    to: '/mnStack',
   // },
   // {
   //     key: 'data-storage',
   //     title: '分布式云存储',
   //     to: '/data-storage',
   // },
    {
        key: 'box',
        title: 'CDN云盒系列',
        to: '/box',
    },
    {
        key: 'big-data',
        title: '边缘计算',
        to: '/big-data',
    },
    // {
    //     key: 'cloud-security',
    //     title: '云安全',
    //     to: '/cloud-security',
    // },

     {
         key: 'solution',
        title: '代理招募',
         to: '/solution',
     },
  
    
    /*{
        key: 'recruitment',
        title: '帮助中心',
        to: '/recruitment',
    },
    */
    {
        key: 'about',
        title: '关于我们',
        to: '/about',
    },
   /*
    {
         key: 'cloud-service',
         title: '登录云平台',
         to: '/cloud-service',
     },
    */
    // {
    //     key: 'product2',
    //     title: '产品2',
    //     to: '/product2',
    // },
]