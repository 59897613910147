
export interface Link {
    key: number | string;
    name: string;
    to?: string;
}

export interface Menu {
    id: number | string;
    title: string;
    list: Link[];
}

export const menus: Menu[] = [
/*
    {
        id: 1,
        title: '私有云存储',
        list: [
            { key: 0, name: '产品优势', to: '/mnStack#desc', },
            { key: 1, name: '新一代私有云', to: '/mnStack#tech', },
            { key: 2, name: '产品能力', to: '/mnStack#spect', },
            { key: 3, name: '产品服务', to: '/mnStack#service', },
            { key: 4, name: '应用场景', to: '/mnStack#scene', },
        ]
    },
 */   
  /* 
    {
        id: 2,
        title: '分布式云存储',
        list: [
            { key: 0, name: '存储优势', to: '/data-storage#advantage', },
            { key: 1, name: '技术规格', to: '/data-storage#specification', },
            { key: 2, name: '应用场景', to: '/data-storage#scene', },
            { key: 3, name: '数据迁移', to: '/data-storage#data-migrate', },
            { key: 4, name: '私有云存储', to: '/data-storage#store', },
        ]
    },
   */
    {
        id: 8,
        title: 'CDN云盒系列产品',
        list: [
            { key: 0, name: '家庭机顶盒', to: '/box#desc', },
            { key: 1, name: 'WIFI路由器', to: '/box#tech', },
            { key: 2, name: '智能化家庭NAS存储', to: '/box#spect', },
        //    { key: 3, name: '云家庭数据中心', to: '/box#service', },
        //    { key: 4, name: '应用场景', to: '/box#scene', },
            { key: 5, name: '共存共创共享', to: '/box#share', },
        ]
    },

    {
        id: 3,
        title: '边缘计算',
        list: [
            { key: 0, name: '边缘计算介绍', to: '/big-data#basic-service', },
            { key: 1, name: '边缘计算优势', to: '/big-data#data-analyze', },
            // { key: 2, name: '数据应用', to: '/big-data#data-application', },
        ]
    },
    // {
    //     id: 4,
    //     title: '云安全',
    //     list: [
    //         { key: 0, name: '安全通讯网络', to: '/cloud-security#network', },
    //         { key: 1, name: '安全区域边界', to: '/cloud-security#area-boundary', },
    //         { key: 2, name: '安全计算环境', to: '/cloud-security#computing-env', },
    //         { key: 3, name: '安全管理中心', to: '/cloud-security#manage-center', },
    //     ]
    // },
    // {
    //     id: 5,
    //     title: '云服务',
    //     list: [
    //         { key: 0, name: '云管平台', to: '/cloud-service#platform', },
    //         { key: 1, name: '平台特征', to: '/cloud-service#feature', },
    //         { key: 2, name: '运维服务', to: '/cloud-service#maintenance', },
    //     ]
    // },
    // {
    //     id: 6,
    //     title: '解决方案',
    //     list: [
    //         { key: 0, name: '云平台解决方案', to: '/solution#cloud-platform', },
    //         { key: 1, name: '资源管理解决方案', to: '/solution#resource-manage', },
    //         { key: 2, name: '资源迁移', to: '/solution#resource-migrate', },
    //         { key: 3, name: '云存储解决方案', to: '/solution#cloud-store', },
    //     ]
    // },

    {
        id: 7,
        title: '关于我们',
        list: [
            { key: 0, name: '企业介绍', to: '/about#intro', },
        //    { key: 1, name: '企业文化', to: '/about#culture', },
            { key: 2, name: '研发中心', to: '/about#dev-center', },
        //   { key: 3, name: '我们的客户', to: '/about#customer', },
            { key: 4, name: '联系我们', to: '/about#contact', },
        ]
    },

    /*
    {
        id: 9,
        title: '招贤纳士',
        list: [
            
        ]
    },
    */
]