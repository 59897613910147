import React from 'react';
import { BackTop as AntdBackTop } from 'antd';

import styles from './index.module.scss';

const style: React.CSSProperties = {
    height: 50,
    width: 50,
    lineHeight: '40px',
    borderRadius: '10px',
    backgroundColor: styles.colorPrimary,
    color: '#fff',
    textAlign: 'center',
    fontSize: 14,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'solid 1px rgba(255,255,255,0.3)', 
};

const BackTop = () => {
    return (
        <AntdBackTop
            visibilityHeight={100}
            style={{
                right: '40px',
                bottom: '30px',
            }}
        >
            <div style={style}>
                <svg
                    viewBox={"0 0 1025 1024"}
                    version={"1.1"}
                    xmlns={"http://www.w3.org/2000/svg"}
                    p-id={"3572"}
                    data-spm-anchor-id={"a313x.7781069.0.i1"}
                    width={"24"}
                    height={"24"}
                >
                    <path
                        d={"M533.205 247.84c-11.436-13.315-30.185-13.315-41.62 0l-342.38 399.573c-11.436 13.32-6.435 24.257 11.194 24.257h128c17.622 0 31.996 14.38 31.996 32.008v256.048c0 17.626 14.378 32.004 32.001 32.004H672.4c17.628 0 31.996-14.379 31.996-32.004V703.68c0-17.63 14.376-32.008 32.005-32.008h128c17.62 0 22.624-10.938 11.185-24.257L533.205 247.84zM192.396 159.574h640.002c35.376 0 64-28.634 64-64.012 0-35.383-28.624-64.012-64-64.012H192.396c-35.371 0-63.998 28.63-63.998 64.012 0 35.378 28.626 64.012 63.998 64.012z"}
                        p-id={"3573"}
                        fill={"#ffffff"}
                    />
                </svg>
            </div>
        </AntdBackTop>
    )
}

export default BackTop;