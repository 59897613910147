import React from 'react';
import { Row, Col } from 'antd';

import IconHeader1 from '@/assets/footer/header-1.svg';
import IconHeader2 from '@/assets/footer/header-2.svg';
import IconHeader3 from '@/assets/footer/header-3.svg';

import styles from './index.module.scss'


const Header = () => {
    return (
        <div className={styles.container}>
            <Row className={styles.header} justify={'center'}>
                <Col span={8}>
                    <Row align={'middle'}>
                        <Col>
                            <img src={IconHeader1} alt={'header-1'} style={{ paddingRight: 10 }} />
                        </Col>
                        <Col>
                            <p style={{ lineHeight: '19px', fontSize: 16 }}>  </p>
                        </Col>
                    </Row>
                </Col>
                <Col span={8}>
                    <Row justify={'center'} align={'middle'}>
                        <Col>
                            <img src={IconHeader2} alt={'header-2'} style={{ paddingRight: 10 }} />    
                        </Col>
                        
                        <Col>
                            <p style={{ lineHeight: '19px', fontSize: 16 }}>   </p>
                        </Col>
                        
                    </Row>
                </Col>
                <Col span={8}>
                    <Row justify={'end'} align={'middle'}>
                        <Col>
                            <img src={IconHeader3} alt={'header-3'} style={{ paddingRight: 10 }} />
                        </Col>
                        <Col>
                            <p style={{ lineHeight: '19px', fontSize: 16 }}>   </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            
            <div className={styles.divider} />
        </div>
    )
}

export default Header