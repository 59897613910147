import React from 'react';
import styles from './index.module.scss';

const MsgWindow = () => {
    return (
        <div className={styles.msgWindow}>
            <div className={styles.kf}>
                <p className={styles.kft}>在线客服</p>
                <p className={styles.kfs}>为您制定专属的服务计划</p>
                <p className={styles.kfs}>(工作日9:00—18:00)</p>
            </div>
            {/* <div className={styles.phone}>
                <p className={styles.label}>售后电话</p>
                <p className={styles.text}>400-108-6788</p>
                <p className={styles.ps}>来电咨询，专家为您解答</p>
                <p className={styles.ps}>(工作日9:00—18:00)</p>
            </div> */}
            <div className={styles.submit}>
                <p
                    className={styles.label}
                    onClick={() => {
                        window.open(`/contact-us`, '_blank');
                    }}
                >
                    提交需求
                </p>
                <p className={styles.text}>提交您的产品需求</p>
            </div>
        </div>
    )
}

export default MsgWindow