import React, { useState } from 'react';
import classnames from 'classnames';

//import Logo from '@/assets/nav/logo.svg'
import Logo from '@/assets/nav/logo1.svg'
//import Logo from '@/assets/nav/logo2.png'
import styles from './index.module.scss';

const HeaderTop = () => {
    const [isConsoleActive, setIsConsoleActive] = useState<boolean>(false);

    return (
        <div className={styles.headerTop}>
            <div className={styles.content}>
                <div>
                    <img src={Logo} alt={'logo'} style={{ width: 114, height: 62 ,float: 'left' }} />
                </div>
                <div
                    onClick={() => {
                        window.open('https://mng.domipayment.com/');
                    }}>
                    <p
                        className={classnames(
                            styles.btnConsole, 
                            isConsoleActive && styles.btnConsoleActive
                        )}
                        onMouseOver={() => setIsConsoleActive(true)}
                        onMouseOut={() => setIsConsoleActive(false)}
                    >
                        控制台(部署中)
                    </p>
                </div>
            </div>
        </div>
    )
}

export default HeaderTop;