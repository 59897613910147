import React, { Suspense } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { IRoute } from './config';
import Loading from '@/components/Loading';
// import LazyLoader from '@/components/LazyLoader';

const RouteWithSubRoutes = (route: IRoute) => {

    const authenticated: boolean = true;

    return (
        <Suspense fallback={route?.fallback || <Loading />} >
            <Route
                path={route.path}
                render={(props) => {
                    if(route.redirect) {
                        return <Redirect to={route.redirect} />;
                    }

                    if(route.private) {
                        return authenticated ? route.component &&
                            // <LazyLoader fallback={route?.fallback || <Loading />}>
                                <route.component {...props} routes={route.routes} />
                            // </LazyLoader>
                            : <Redirect to={'/'} />
                    }

                    return route.component &&
                        // <LazyLoader fallback={route?.fallback || <Loading />}>
                            <route.component {...props} routes={route.routes} />
                        // </LazyLoader>;
                }
                }
            />
        </Suspense>
    );
};

export default RouteWithSubRoutes;