import React, { useRef } from 'react';

import { routes } from "@/router/config";
import Router from "@/router/Router";
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import BackTop from '@/components/BackTop';
import Sticker from '@/components/Sticker'
import DownloadApp from './components/DownloadApp';
const App = () => {
    const containerRef = useRef(null);

    return (
        <div style={{ position: 'relative' }}>
            <Header />
            <div ref={containerRef} >
                <Router routes={routes} />
            </div>
            <Footer />
            <DownloadApp />
            <Sticker />
            <BackTop />
        </div>
    )
}

export default App;